import React from 'react'
// import { Link } from 'gatsby'
import Nav from './Nav'
// import topspinlogo from '../assets/images/topspinlogo.png'

const Header = () => (
  <div>
    <Nav />
  </div>
)

export default Header
