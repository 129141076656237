import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

import topspinlogo from '../assets/images/topspinlogo.png'

const Nav = ({ siteTitle }) => (
    <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="container">
            <div className="navbar-brand">
                <Scroll type="id" element="intro" offset={-90}>
                    <a className="navbar-item" href="#"><img src={topspinlogo} alt={siteTitle} /></a>
                </Scroll>
                <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navMenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="navMenu" className="navbar-menu">
                <div className="navbar-end">
                    <Scrollspy items={ ['intro', 'products', 'about', 'team', 'mission','services', 'contact'] } currentClassName="is-active" offset={-100} componentTag="div" className="is-inline-flex">
                        <span className="navbar-item">
                            <Scroll type="id" element="intro" offset={-90}>
                                <a href="#">Home</a>
                            </Scroll>
                        </span>
                        <span className="navbar-item">
                            <Scroll type="id" element="products" offset={-90}>
                                <a href="#">Products</a>
                            </Scroll>
                        </span>
                        <span className="navbar-item">
                            <Scroll type="id" element="about">
                                <a href="#">About Us</a>
                            </Scroll>
                        </span>
                        <span className="navbar-item">
                            <Scroll type="id" element="team" offset={-70}>
                                <a href="#">Our Team</a>
                            </Scroll>
                        </span>
                        <span className="navbar-item">
                            <Scroll type="id" element="mission">
                                <a href="#">Our Mission</a>
                            </Scroll>
                        </span>
                        <span className="navbar-item">
                            <Scroll type="id" element="services">
                                <a href="#">Services</a>
                            </Scroll>
                        </span>
                        <span className="navbar-item">
                            <Scroll type="id" element="contact">
                                <a  href="#">Contact Us</a>
                            </Scroll>
                        </span>
                    </Scrollspy>
                </div>
            </div>
        </div>
    </nav>
)

export default Nav
