import React from 'react'
// import { Link } from 'gatsby'

const Footer = () => (
  <div>
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          &copy; 2018 - Top Spin Foods Corp. - All Rights Reserved.
        </p>
      </div>
    </footer>
  </div>
)

export default Footer